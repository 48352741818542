const FILTER_MAX_DISTANCE = "30";

const formatTHCFilterKey = (
  values: string,
  index = 0,
): { [key: string]: string } => {
  const [gte, lte] = values.replace("*", "").split("-");

  const formattedFilter: { [key: string]: string } = {};
  if (gte) {
    formattedFilter[`filter[thc][${index}][gte]`] = gte;
  }
  if (lte) {
    formattedFilter[`filter[thc][${index}][lte]`] = lte;
  }
  return formattedFilter;
};

export const formatFilterKey = (
  key: string,
  value: string | string[],
  enableNewFilters?: boolean,
  index?: number,
): { [key: string]: string | string[] | boolean } => {
  const formattedKey = key.replaceAll("[]", "");
  switch (formattedKey) {
    case "helps_with":
    case "strain_category":
    case "strain_top_terp":
    case "thc":
    case "top_flavors_included":
    case "top_strain_effects_included":
    case "top_strain_negatives_excluded":
      return {
        [`filter[${formattedKey}]`]:
          typeof value === "string" ? [value] : value,
      };
    case "conditions_included":
      return {
        [`filter[conditions.${value}]`]: true,
      };
    case "effects_excluded":
      return {
        [`filter[effects.${value}]`]: false,
      };
    case "effects_included":
      return {
        [`filter[effects.${value}]`]: true,
      };
    case "flavors_included":
      return {
        [`filter[flavors.${value}]`]: true,
      };
    case "negatives_excluded":
      return {
        [`filter[negatives.${value}]`]: false,
      };
    case "negatives_included":
      return {
        [`filter[negatives.${value}]`]: true,
      };
    case "online_fulfillment_enabled":
      return {
        [`filter[max_distance]`]: FILTER_MAX_DISTANCE,
        [`filter[online_fulfillment_enabled]`]: true,
      };
    case "phenotype":
      return {
        [`filter[strain_category][]`]:
          String(value).charAt(0).toUpperCase() + String(value).slice(1),
      };
    case "symptoms_included":
      return {
        [`filter[symptoms.${value}]`]: true,
      };
    case "thc_ranges":
      return enableNewFilters
        ? { [`filter[thc]`]: value }
        : formatTHCFilterKey(String(value), index);
    default:
      return {};
  }
};

export const formatSortParam = (value: string, index = 0) => {
  const [query, direction] = value.split(":");
  return { [`sort[${index}][${query}]`]: direction };
};
