import { useMemo } from "react";

import { Facet } from "components/FilteredGrid";

import {
  MenuFilterGroupValues,
  MenuFilterMap,
  MenuFiltersGroup,
} from "../../types/MenuFilters";
import useFilterQueryParams from "./useFilterQueryParams";

// * Sometimes we link to /shop with filters that aren't
// *  included in the API metadata due to our 1000 bucket
// *  limit for elasticsearch aggregations. This hook creates
// *  facets for query params that fit that criteria
// * Background: https://leafly.atlassian.net/browse/SHH-754
const useNonAPISupportedFilterFacets = (menuFilterMap?: MenuFilterMap) => {
  const { appliedFilters: filterValues } = useFilterQueryParams();

  const nonAPISupportedFacets: Facet[] = useMemo(
    () =>
      Object.entries(filterValues).reduce<Facet[]>(
        (accumulator, [filterKey, filterValue]): Facet[] => {
          // * This hook ignores empty or boolean query string values.
          // * Boolean filters don't run into the metadata issue described in the hook outline.
          if (!filterValue || filterValue === "true") {
            return accumulator;
          }

          const apiSupportedFilterCategory = menuFilterMap
            ? (menuFilterMap[filterKey] as MenuFiltersGroup)
            : null;
          const apiSupportedFilterOptions: MenuFilterGroupValues[] =
            apiSupportedFilterCategory?.values || [];
          const apiSupportedFilterValues = apiSupportedFilterOptions.map(
            (f) => f.value,
          );
          const nonAPISupportedValues = (
            Array.isArray(filterValue) ? filterValue : [filterValue]
          ).filter(
            (appliedFilter) =>
              !apiSupportedFilterValues.includes(appliedFilter),
          );

          return [
            ...accumulator,
            ...nonAPISupportedValues.map((nonAPIFilterValue) => ({
              key: filterKey,
              label: nonAPIFilterValue,
              value: nonAPIFilterValue,
            })),
          ];
        },
        [],
      ),
    [menuFilterMap, filterValues],
  );

  return nonAPISupportedFacets;
};

export default useNonAPISupportedFilterFacets;
