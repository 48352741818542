import { useCallback, useMemo } from "react";

import { Action, Category } from "constants/events";
import { useEventTracker } from "hooks/useEventTracker";
import useFilters from "hooks/useFilters";
import { FILTER_EVENT_MAP } from "utils/strainsListsFilterUtils";

import { MenuPage } from "../types/MenuFilters";

const DropdownSortStrings: Record<string, string> = {
  "cbd_content:asc": "cbd: low-high",
  "cbd_content:desc": "cbd: high-low",
  "price:asc": "price: low-high",
  "price:desc": "price: high-low",
  recommended: "recommended",
  "thc_content:asc": "thc: low-high",
  "thc_content:desc": "thc: high-low",
};

const usePublishFilterClickEvent = (menuPage = MenuPage.dispensary) => {
  const { filterValues } = useFilters();
  const { publishEvent } = useEventTracker();

  const { category, labelPrefix, sortCategory } = useMemo(() => {
    switch (menuPage) {
      case MenuPage.strains:
        return {
          category: Category.strainsFilter,
          labelPrefix: true,
          sortCategory: Category.strainsSort,
        };
      case MenuPage.delivery:
        return {
          category: Category.deliveryGateFlow,
          labelPrefix: false,
          sortCategory: undefined,
        };
      case MenuPage.brand:
        return {
          category: Category.brandPaid,
          labelPrefix: false,
          sortCategory: Category.brandPaid,
        };
      case MenuPage.subcategory:
        return {
          category: Category.productSubcategoryPage,
          labelPrefix: false,
          sortCategory: Category.productSubcategoryPage,
        };
      default:
        return {
          category: Category.dispensaryMenuFilter,
          labelPrefix: false,
          sortCategory: Category.dispensaryMenuSort,
        };
    }
  }, [menuPage]);

  const parseLabel = useCallback(
    (filterLabel: string, filterOption?: string, filterName?: string) => {
      const usePrefix = labelPrefix && filterName && filterOption;
      const isSelected =
        usePrefix && filterValues[filterName]?.includes(filterOption);

      const label = filterLabel.replace(/_+|-+/g, " ");

      if (menuPage === MenuPage.delivery || menuPage === MenuPage.brand) {
        return filterName
          ? `filter button ${filterName}`
          : `filter section ${filterLabel}`;
      }

      return usePrefix
        ? `${isSelected ? "Remove" : "Add"} ${
            FILTER_EVENT_MAP[filterName as string]
          } ${label}`
        : label;
    },
    [labelPrefix, filterValues],
  );

  const publishSortDropDownClick = useCallback(
    (sortName: string) => {
      if (!sortCategory) {
        return;
      }
      publishEvent({
        action: Action.click,
        category: sortCategory,
        label: DropdownSortStrings[sortName.toLowerCase()],
      });
    },
    [publishEvent, sortCategory],
  );

  const publishFilterSectionClickEvent = useCallback(
    (sectionTitle: string) => {
      publishEvent({
        action: Action.click,
        category,
        label: parseLabel(sectionTitle.toLowerCase()),
      });
    },
    [parseLabel, publishEvent, category],
  );

  const publishFilterClickEvent = useCallback(
    (filterLabel: string, filterOption?: string, filterName?: string) => {
      publishEvent({
        action: Action.click,
        category,
        label: parseLabel(filterLabel.toLowerCase(), filterOption, filterName),
      });
    },
    [parseLabel, publishEvent, category],
  );

  const publishFilterPanelImpression = {
    action: Action.impression,
    category: Category.deliveryGateFlow,
    label: "filter panel",
    nonInteractionEvent: true,
  };

  return {
    publishFilterClickEvent,
    publishFilterPanelImpression,
    publishFilterSectionClickEvent,
    publishSortDropDownClick,
  };
};

export default usePublishFilterClickEvent;
