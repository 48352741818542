import React from "react";
import cx from "classnames";

import FilterIcon from "components/Icons/filter.svg";

export const FilterButton = React.forwardRef<
  HTMLButtonElement,
  {
    filterCount?: number;
    onClick?: () => void;
    className?: string;
  }
>(({ filterCount = 0, onClick = () => {}, className }, ref) => (
  <button
    onClick={onClick}
    ref={ref}
    className={cx(
      "border border-light-grey flex font-bold items-center leading-none px-md py-sm rounded text-green text-xs flex-shrink-0",
      className,
    )}
  >
    <span className="pr-xs">
      <FilterIcon width="12" height="11" className="text-green" />
    </span>
    {filterCount ? `(${filterCount})` : "Filters"}
  </button>
));
