import classNames from "classnames";

import ChevronIcon from "components/Icons/ChevronIcon";

const FilterShortcut: React.FC<{
  showChevron?: boolean;
  text: string;
  onClick: () => void;
}> = ({ showChevron = false, text, onClick }) => {
  return (
    <button
      onClick={onClick}
      className={classNames(
        "border border-light-grey flex font-bold items-center leading-none  rounded text-green text-xs min-w-max",
        {
          "p-2": !showChevron,
          "pl-md pr-sm py-xs": showChevron,
        },
      )}
      data-testid="mobile-filter-shortcut"
    >
      {text}
      {showChevron && (
        <ChevronIcon
          direction="right"
          className="flex-none"
          height="20"
          width="20"
        />
      )}
    </button>
  );
};

export default FilterShortcut;
