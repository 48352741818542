import React from "react";

type Props = {
  displayName: string;
  colorClassName: string;
};

const TerpeneLabel = ({ displayName, colorClassName }: Props) => {
  return (
    <>
      <span
        className={`inline-block px-xs py-sm ml-sm mr-xs rounded-full text-center ${colorClassName}`}
      ></span>
      <span className="capitalize">{displayName}</span>
    </>
  );
};

export default TerpeneLabel;
