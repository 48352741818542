import React from "react";

import AddIcon from "components/Icons/plus.svg";
import Placeholder, { Rect } from "components/Placeholder";

// the width of each filter section placeholder
const FILTER_SECTIONS = [94, 55, 81, 117, 70, 56];

const DesktopFilterPlaceholder: React.FC<{
  showTitle?: boolean;
}> = ({ showTitle = false }) => (
  <>
    {showTitle && (
      <div className="hidden text-xs mb-sm md:flex justify-between items-center">
        <span className="font-extrabold uppercase">Filters</span>
      </div>
    )}

    {FILTER_SECTIONS.map((width: number) => (
      <DesktopFilterSectionPlaceholder width={width} key={width} />
    ))}

    <div className="flex items-center px-md mt-sm mb-lg md:px-none md:mt-none md:mb-none py-md">
      <Placeholder width="20" height="20" className="mr-md">
        <Rect width="100%" height="100%" />
      </Placeholder>
      <div>
        <Placeholder width="130" height="18" className="mb-sm">
          <Rect width="100%" height="100%" />
        </Placeholder>
        <Placeholder width="150" height="18">
          <Rect width="100%" height="100%" />
        </Placeholder>
      </div>
    </div>
  </>
);

const DesktopFilterSectionPlaceholder: React.FC<{
  width: number;
}> = ({ width = 100 }) => (
  <div
    className="border-t border-b border-light-grey"
    style={{ marginTop: "-1px" }}
  >
    <div className="flex justify-between items-center w-full p-md md:pl-none">
      <span className="font-bold">
        <Placeholder width={`${width}`} height="23">
          <Rect width="100%" height="100%" />
        </Placeholder>
      </span>
      <div className="flex items-center">
        <span>
          <AddIcon height="24" width="24" />
        </span>
      </div>
    </div>
  </div>
);

export default DesktopFilterPlaceholder;
