import React from "react";
import classNames from "classnames";

import IconSvg from "components/IconSvg";
import TileSkeleton from "components/Shared/Skeletons/TileSkeleton";

type Props = {
  className?: string;
  count?: number;
  disabled?: boolean;
  iconPath: string | null;
  label: string;
  onClick?: () => void;
  selected?: boolean;
  loading?: boolean;
  lowerCase?: boolean;
  showCount?: boolean;
};

/**
 * A button interface that's suited for use in filter interface with a small
 * number of options (e.g. strain type, effects, etc.).
 */
export const FilterTile = ({
  className,
  count = 0,
  disabled = false,
  iconPath,
  label,
  onClick = () => {},
  selected = false,
  loading,
  showCount = true,
  lowerCase,
}: Props) => {
  const isDisabled = disabled || count === 0;

  return loading ? (
    <TileSkeleton />
  ) : (
    <button
      className={classNames(
        "flex flex-col items-center border-2 text-xs py-sm w-full rounded overflow-hidden",
        {
          "border-light-grey": !selected,
          "cursor-default": isDisabled,
          "cursor-pointer": !isDisabled,
          "opacity-50": isDisabled,
        },
        className,
      )}
      onClick={onClick}
      disabled={isDisabled}
    >
      {iconPath ? (
        <IconSvg
          height="24"
          width="24"
          className="block mb-xs"
          filePath={iconPath}
        />
      ) : null}

      <span
        className={classNames("font-bold leading-none mb-xs w-full truncate", {
          capitalize: !lowerCase,
          lowerCase,
        })}
      >
        {label}
      </span>
      {showCount && (
        <span className={classNames("leading-none text-grey")}>
          ({count.toLocaleString("en-us")})
        </span>
      )}
    </button>
  );
};
