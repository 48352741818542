import { useEffect, useRef } from "react";

export default function useClickAwayListener(
  onClickAway: () => void,
  elementIsVisible: boolean,
) {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const clickAwayListenerRef = useRef((e: MouseEvent | TouchEvent) => {
    if (wrapperRef.current && !wrapperRef.current.contains(e.target as Node)) {
      onClickAway();
    }
  });

  useEffect(() => {
    if (elementIsVisible) {
      window.addEventListener("mousedown", clickAwayListenerRef.current);
      window.addEventListener("touchend", clickAwayListenerRef.current);
    } else {
      window.removeEventListener("mousedown", clickAwayListenerRef.current);
      window.removeEventListener("touchend", clickAwayListenerRef.current);
    }

    return () => {
      window.removeEventListener("mousedown", clickAwayListenerRef.current);
      window.removeEventListener("touchend", clickAwayListenerRef.current);
    };
  }, [elementIsVisible]);

  return wrapperRef;
}
