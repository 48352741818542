import { useRouter } from "next/router";

import { STRAIN_LIST_PAGE_CATEGORY_FILTERS } from "api/requests/getStrainLists";

const useIsStrainListPage = () => {
  const { route, query } = useRouter();

  const isStrainListPage = route === "/strains/lists/[...slug]";
  const strainListPageCategory = isStrainListPage && query.slug?.[0];
  const strainListPageSlug =
    strainListPageCategory && strainListPageCategory !== "curated"
      ? query.slug?.[1]
      : undefined;
  const strainListPageName = strainListPageSlug?.replaceAll("-", " ");

  const isStrainListPageNameEqualToFilterValue = (value?: string) =>
    !!(
      strainListPageName &&
      value &&
      strainListPageName.replaceAll("-", "_").toLowerCase() ===
        value.toLowerCase()
    );

  const strainListPageFilter =
    STRAIN_LIST_PAGE_CATEGORY_FILTERS[
      strainListPageCategory as keyof typeof STRAIN_LIST_PAGE_CATEGORY_FILTERS
    ];
  const isStrainListPageFilter = (name: string) =>
    strainListPageFilter === name;

  return {
    isStrainListPageFilter,
    isStrainListPageNameEqualToFilterValue,
    strainListPageName,
  };
};

export default useIsStrainListPage;
