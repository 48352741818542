import { HTMLAttributes, useState } from "react";
import classNames from "classnames";

import useFilters from "hooks/useFilters";

import { MenuPage } from "components/MenuFilters/types/MenuFilters";
import Placeholder, { Rect } from "components/Placeholder";

import { FilterChips } from "./FilterChips";
import { FiltersPlaceholder } from "./FiltersPlaceholder";
import { MobileFilters, MobileFilterShortcutsType } from "./MobileFilters";

export type Facet = {
  key: string;
  label: string;
  value: string;
};

interface FilteredGridProps extends HTMLAttributes<HTMLDivElement> {
  confirmationButtonText: string;
  renderFilters: (facets: Facet[], defaultOpenKey?: string) => React.ReactNode;
  renderChips?: (facets: Facet[]) => React.ReactNode;
  renderHeader?: () => React.ReactNode;
  // * NOTE: This is not an additive onClearAllClick.
  // * This rather overrides the baked in behavior of this component.
  // * See ProductFilterView for primary usage of this.
  onClearAllClick?: () => void;
  mobileShortcuts?: MobileFilterShortcutsType;
  loading?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  availableFilters?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  mobileFilterPanelImpression?: any;
  onMobileFilterButtonClick?: () => void;
  mobileClassName?: string;
  apiSupportedFacets?: Facet[];
  menuPage?: MenuPage;
  fulfillmentModeUnavailable?: boolean;
}

export const defaultRenderChips = (facets: Facet[]) => (
  <FilterChips facets={facets} />
);

/**
 * This component renders a layout comprising filters in a left-hand column,
 * and some content in a larger right-hand column. On smaller viewports, these
 * columns are removed; filters are displayed behind a button press in a modal
 * instead of in a separate column.
 *
 * The `renderFilters` prop should be provided a React component that will be
 * rendered in the appropriate places in both layouts.
 */
export const FilteredGrid: React.FC<FilteredGridProps> = ({
  children,
  className,
  confirmationButtonText,
  renderFilters,
  renderChips = defaultRenderChips,
  renderHeader,
  onClearAllClick,
  mobileShortcuts,
  availableFilters,
  loading = false,
  apiSupportedFacets,
  mobileFilterPanelImpression,
  onMobileFilterButtonClick,
  mobileClassName,
  menuPage,
  fulfillmentModeUnavailable,
  ...props
}) => {
  const [openMobileSectionKey, setOpenMobileSectionKey] = useState<string>();
  const { clearAllFilters, filterValues } = useFilters({
    ignoredQueries: ["subcategoryPageTab"],
  });
  const facets: Facet[] = apiSupportedFacets || [];

  if (!apiSupportedFacets) {
    Object.keys(filterValues).forEach((key) => {
      const value = filterValues[key];

      if (!value) {
        return;
      }

      if (Array.isArray(value)) {
        value.forEach((v) => {
          facets.push(createFacet(key, v));
        });
      } else {
        facets.push(createFacet(key, value));
      }
    });
  }

  const clearFilters = () =>
    onClearAllClick ? onClearAllClick() : clearAllFilters(["sort"]);

  return (
    <section
      className={classNames("container", className)}
      style={{ isolation: "isolate" }}
      data-testid="filtered-grid"
      {...props}
    >
      {renderHeader && (
        <div className="grid md:grid-cols-[270px_1fr]">
          <div className="grid col-start-2">{renderHeader()}</div>
        </div>
      )}
      <div className="grid grid-cols-1 gap-1 md:gap-8 md:grid-cols-4 md:m-0">
        <div>
          <div
            className={classNames("block relative md:hidden", {
              "fade-white-right": !!mobileShortcuts,
            })}
          >
            {loading ? (
              <Placeholder
                width="80"
                height="30"
                className="border border-light-grey rounded mt-md mb-md"
              >
                <Rect width="100%" height="100%" />
              </Placeholder>
            ) : (
              !fulfillmentModeUnavailable && (
                <MobileFilters
                  confirmationButtonText={confirmationButtonText}
                  facets={facets}
                  shortcuts={mobileShortcuts}
                  onShortcutClick={setOpenMobileSectionKey}
                  filterPanelImpression={mobileFilterPanelImpression}
                  onMobileFilterButtonClick={onMobileFilterButtonClick}
                  availableFilters={availableFilters}
                  renderChips={renderChips}
                  mobileClassName={mobileClassName}
                  menuPage={menuPage}
                >
                  {!!facets.length && (
                    <div data-testid="facets-div" className="p-4">
                      <div className="flex items-center justify-between mb-sm mt-sm">
                        <span className="font-bold">Filtered by:</span>
                        <button
                          className="text-xs font-bold underline"
                          onClick={clearFilters}
                          data-testid="clear-all-mobile-filters"
                        >
                          clear all
                        </button>
                      </div>
                      <div
                        className="pb-2 overflow-x-auto"
                        data-testid="filter-chips"
                      >
                        {renderChips(facets)}
                      </div>
                    </div>
                  )}

                  {renderFilters(facets, openMobileSectionKey)}
                </MobileFilters>
              )
            )}
          </div>
          {!fulfillmentModeUnavailable && (
            <div className="hidden md:block" data-testid="filters">
              <div className="hidden text-xs mb-sm md:flex justify-between items-center">
                <span className="font-extrabold uppercase">Filters</span>

                {!!facets.length && (
                  <button
                    className="underline text-xs font-bold"
                    onClick={clearFilters}
                    data-testid="clear-all-filters"
                  >
                    clear all
                  </button>
                )}
              </div>

              {loading ? (
                <FiltersPlaceholder />
              ) : (
                <>
                  <div className="mb-4" data-testid="filter-chips">
                    {renderChips(facets)}
                  </div>
                  {renderFilters(facets)}
                </>
              )}
            </div>
          )}
        </div>

        <div className="md:col-span-3">{children}</div>
      </div>
      <style jsx>{`
        .filter-bar__fade::after {
          width: 32px;
          pointer-events: none;
        }
      `}</style>
    </section>
  );
};

function createFacet(key: string, value: string): Facet {
  const label = value === "true" ? key : value.replace("-", " ");
  const pluralizedKey =
    value === "true" || value === "false" ? key : `${key}[]`;
  return {
    key: pluralizedKey,
    label,
    value,
  };
}
