import React from "react";
import classNames from "classnames";

import Checkbox from "components/botanic/Checkbox";
import { CheckBoxSkeleton } from "components/Shared/Skeletons";

type Props = {
  children: string | React.ReactNode;
  className?: string;
  count?: number;
  disabled?: boolean;
  name: string;
  onChange?: () => void;
  selected?: boolean;
  value?: string;
  showCount?: boolean;
  loading?: boolean;
};

const FilterCheckbox = ({
  children,
  className = "",
  count = 0,
  disabled = false,
  name,
  onChange = () => {},
  selected = false,
  showCount = true,
  loading,
  value,
}: Props) => {
  const isDisabled = disabled || count === 0;

  return loading ? (
    <CheckBoxSkeleton />
  ) : (
    <Checkbox
      id={`${name}_${value}`}
      data-testid={`${name}_${value}`}
      disabled={isDisabled}
      name={name}
      value={value}
      checked={selected}
      className={classNames(
        "flex p-xs w-full",
        { "border-light-grey": !selected },
        { "cursor-pointer": !isDisabled },
        { "opacity-50": count === 0 },
        className,
      )}
      onChange={onChange}
    >
      <div className="flex items-center">
        {children}
        {showCount && <span className="pl-sm text-grey">({count})</span>}
      </div>
    </Checkbox>
  );
};

export default FilterCheckbox;
