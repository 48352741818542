import { useCallback } from "react";

import useFilters from "hooks/useFilters";

import { Facet } from "components/FilteredGrid";

/**
 * useChips: returns a default click handler for our Filter Chips UI
 *
 * @param useFilterOptions - options that are forwarded to the useFilters hook
 * @returns handleFilterChipClick - A default click handler that uses toggleFilter
 *   under the hood.
 */
const useChips = (useFilterOptions?: Parameters<typeof useFilters>[0]) => {
  const { toggleFilter } = useFilters(useFilterOptions);
  const handleFilterChipClick = useCallback(
    (facet: Facet) => {
      if (facet.key === "q[]") {
        return toggleFilter({ q: facet.value });
      }
      toggleFilter({ [facet.key]: facet.value });
    },
    [toggleFilter],
  );

  return { handleFilterChipClick };
};

export default useChips;
