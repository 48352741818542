import React from "react";
import classNames from "classnames";

import ClearIcon from "components/Icons/x.svg";

import { Facet } from "./FilteredGrid";

export const FilterChip = ({
  className,
  facet,
  onClick,
}: {
  className?: string;
  facet: Facet;
  onClick?: (facet: Facet) => void;
}) => (
  <button
    className={classNames(
      "flex items-center text-xs font-bold py-xs px-sm bg-green-20 border border-green-40 rounded flex-shrink-0 capitalize",
      { "cursor-default": !onClick },
      className,
    )}
    onClick={onClick ? () => onClick(facet) : undefined}
  >
    <span className="text-left truncate-lines max-w-[200px]">
      {facet.label}
    </span>
    {onClick && <ClearIcon height="12" width="12" className="ml-xs" />}
  </button>
);
