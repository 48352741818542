import { CancelToken } from "axios";
import mergeWith from "lodash/mergeWith";

import consumerApi from "api/services/consumer-api";
import { Coordinates } from "custom-types/Coordinates";
import { Strain } from "custom-types/Strain";
import { filterToggler } from "hooks/useFilters";
import {
  formatFilterKey,
  formatSortParam,
} from "utils/formatStrainListFilters";
import formatImgixUrl from "utils/image/formatImgixUrl";
import logError from "utils/logError";

import { GetMenuItemsAvailableFilters } from "./getMenuItems";

const DEFAULT_SORT = "recommended:asc";

export const STRAIN_LIST_PAGE_CATEGORY_FILTERS = {
  category: "strain_category",
  condition: "helps_with",
  effect: "top_strain_effects_included",
  flavor: "top_flavors_included",
  terpene: "strain_top_terp",
};

export const ITEMS_PER_STRAIN_PAGE = 18;

export const getStrainListsPageFilterName = (
  pageCategory?: string,
  pageName?: string,
) => {
  const pageCategoryFilter =
    pageCategory &&
    STRAIN_LIST_PAGE_CATEGORY_FILTERS[
      pageCategory as keyof typeof STRAIN_LIST_PAGE_CATEGORY_FILTERS
    ];

  return pageCategoryFilter && pageName
    ? {
        [`${
          STRAIN_LIST_PAGE_CATEGORY_FILTERS[
            pageCategory as keyof typeof STRAIN_LIST_PAGE_CATEGORY_FILTERS
          ]
        }`]: pageName,
      }
    : {};
};

type MetaData = {
  availableFilters: GetMenuItemsAvailableFilters;
  availableSorts: string[] | null;
  totalCount: number;
};

export type getStrainListsReturn = {
  metadata: MetaData;
  strains: Strain[];
};

const getStrainLists = async (
  filters: { [key: string]: string | string[] | boolean | undefined },
  skip = 0,
  take = ITEMS_PER_STRAIN_PAGE,
  coordinates: Coordinates,
  sort?: string,
  cancelToken?: CancelToken,
  pageCategory?: string,
  pageName?: string,
) => {
  // Accounting for consumer-api accepting only the capitalized versions
  // of the phenotype names and underscore values instead of dashes
  const modififiedPageName =
    pageName && ["sativa", "indica", "hybrid"].includes(pageName)
      ? pageName.charAt(0).toUpperCase() + pageName.slice(1)
      : pageName?.replaceAll("-", "_");
  const pageFilter = getStrainListsPageFilterName(
    pageCategory,
    modififiedPageName,
  );
  const combinedFilters = mergeWith(filters, pageFilter, filterToggler);

  const filterParams = Object.entries(combinedFilters).reduce(
    (acc, [key, value]) => ({
      ...acc,
      ...formatFilterKey(key, value, true, 0),
    }),
    {},
  );

  const params = {
    enableNewFilters: true,
    skip,
    strain_playlist: pageCategory === "curated" ? pageName : "",
    take,
    ...coordinates,
    ...filterParams,
    ...formatSortParam(sort ? sort : DEFAULT_SORT),
    ...(pageCategory !== "curated" && pageCategory !== undefined
      ? {
          attribute_name: pageName,
          attribute_type: pageCategory,
        }
      : {}),
    ...(pageCategory === "new-strains"
      ? { "filter[is_new_strain]": true }
      : {}),
    ...(sort === "rating:desc" ? formatSortParam("review_count:desc", 1) : {}),
  };

  try {
    const { data } = await consumerApi.get<{
      hits: {
        strain: Strain[];
      };
      metadata: MetaData;
    }>(`/api/strain_playlists/v2`, {
      cancelToken,
      params,
    });

    const {
      hits: { strain: strains },
      metadata,
    } = data;

    strains?.length &&
      strains.forEach((strain) => {
        if (strain.nugImage) {
          strain.nugImage = formatImgixUrl(strain.nugImage);
        }
      });
    return { metadata, strains };
  } catch (e) {
    if (e.statusCode !== "ERR_CANCELED") {
      logError(e.message, {
        functionName: "getStrainLists",
        service: "consumer",
        statusCode: e.statusCode,
      });
    }

    return {
      metadata: { availableFilters: [], availableSorts: [], totalCount: 0 },
      strains: [],
    };
  }
};

export default getStrainLists;
