import { ChangeEvent, useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import type FuseForTypes from "fuse.js";

import SearchIcon from "components/Icons/search.svg";
import ClearIcon from "components/Icons/x.svg";
interface Props<T> {
  label: string;
  options: T[];
  handleFilterSearch: (options: T[]) => void;
  inputClasses?: string;
  fuseOptions: FuseForTypes.IFuseOptions<T>;
}

const FilterSearch = <T,>({
  label,
  options,
  handleFilterSearch,
  inputClasses,
  fuseOptions,
}: Props<T>) => {
  const [searchValue, setSearchValue] = useState("");
  const searchFilters = useCallback(
    async (searchTerm: string, searchArray: T[]) => {
      const Fuse = (await import("fuse.js")).default;
      const fuse = new Fuse<T>(searchArray, fuseOptions);

      if (searchTerm !== "") {
        handleFilterSearch(fuse.search(searchTerm).map((f) => f.item));
      } else {
        handleFilterSearch(searchArray);
      }
    },
    [fuseOptions, handleFilterSearch],
  );

  // TODO could use a useCallback around lambdas below
  const handleChange = async ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => setSearchValue(value);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) =>
    e.preventDefault();

  const handleClearClick = () => {
    setSearchValue("");
  };

  // * Effect to re-run search if underlying options or search value changes:
  useEffect(() => {
    searchFilters(searchValue, options);
  }, [searchValue, options]);

  const placeholderText = `Search ${label}`;

  const labelSlug = label.replace(/\s/g, "-").trim();

  return (
    <div className="my-sm w-full" style={{ maxWidth: 660 }}>
      <form
        className="relative"
        onSubmit={handleSubmit}
        autoComplete="off"
        data-testid={`${labelSlug}-search-form`}
      >
        <input
          id={`${labelSlug}-search-input`}
          type="text"
          name="search"
          placeholder={placeholderText}
          value={searchValue}
          onChange={handleChange}
          className={classNames("rounded py-sm", inputClasses, {
            "bg-leafly-white": !inputClasses,
          })}
          data-testid={`${labelSlug}-search-input`}
          aria-label={placeholderText}
        />
        <div className="absolute flex mr-md right-0 h-full top-0">
          <button
            id={`${labelSlug}-search-reset`}
            className={classNames({ hidden: !searchValue })}
            type="button"
            onClick={handleClearClick}
            disabled={!searchValue}
            data-testid={`${labelSlug}-search-reset`}
            aria-label={`Clear ${label} search`}
          >
            <ClearIcon height="24" width="24" />
          </button>
          <SearchIcon
            className={classNames("my-sm", { hidden: searchValue })}
            height="24"
            width="24"
          />
        </div>
      </form>
    </div>
  );
};

export default FilterSearch;
