import { ReactNode, useState } from "react";

import {
  DEFAULT_VISIBLE_TAKE,
  FilterCheckboxList,
  FilterCheckboxOption,
} from "components/Filters/FilterCheckboxList";
import FilterSearch from "components/Filters/FilterSearch";

interface Props {
  label: string;
  name: string;
  options: FilterCheckboxOption[];
  onChange?: (changed: FilterCheckboxOption) => void;
  selected?: (option: FilterCheckboxOption) => boolean;
  disabled?: (option: FilterCheckboxOption) => boolean;
  loading?: boolean;
  renderCustomLabel?: ((filter: FilterCheckboxOption) => ReactNode) | undefined;
}

const FilterSearchList = ({
  label,
  name,
  options,
  onChange,
  selected,
  disabled,
  loading,
  renderCustomLabel,
}: Props) => {
  const [subFiltersResult, setSubFiltersResult] = useState(options);
  const handleFilterSearch = (subFilters: FilterCheckboxOption[]) =>
    setSubFiltersResult(subFilters);
  const hideFilterSearch = options.length <= DEFAULT_VISIBLE_TAKE;

  return (
    <>
      {!hideFilterSearch && (
        <FilterSearch
          label={label}
          options={options}
          handleFilterSearch={handleFilterSearch}
          inputClasses=""
          fuseOptions={{ keys: ["key"] }}
        />
      )}
      <FilterCheckboxList
        name={name}
        options={subFiltersResult}
        loading={loading}
        onChange={onChange}
        selected={selected}
        disabled={disabled}
        renderCustomLabel={renderCustomLabel}
      />
    </>
  );
};

export default FilterSearchList;
