import React from "react";

type Props = {
  displayName: string;
  badge: string;
};

const LabelWithBadge = ({ displayName, badge }: Props) => {
  return (
    <>
      {displayName}&nbsp;
      {badge && (
        <span
          className="items-center py-xs px-sm bg-leafly-white rounded text-xs"
          data-testid="label-badge"
        >
          {badge}
        </span>
      )}
    </>
  );
};

export default LabelWithBadge;
