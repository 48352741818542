import { useState } from "react";
import classNames from "classnames";

type Props = {
  children: React.ReactNode;
  expandButtonText: string;
  className?: string;
};

const ExpandableFilterOptions = ({
  children,
  expandButtonText,
  className = "",
}: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <>
      {isExpanded && children}
      {!isExpanded && (
        <button
          onClick={() => setIsExpanded(true)}
          className={classNames(
            "block text-xs text-green font-bold underline mb-lg",
            className,
          )}
        >
          {expandButtonText}
        </button>
      )}
    </>
  );
};
export default ExpandableFilterOptions;
