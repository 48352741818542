import React, { HTMLAttributes } from "react";
import classNames from "classnames";

const DropdownItem: React.FC<HTMLAttributes<HTMLButtonElement>> = ({
  children,
  className,
  ...others
}) => {
  const classes = classNames("dropdown__item", className);

  return (
    <button className={classes} {...others}>
      {children}
    </button>
  );
};

export default DropdownItem;
