import { useMemo } from "react";

import {
  MenuFilterMap,
  MenuFilterOptions,
  MenuFiltersGroup,
  MenuItemFilterSection,
} from "../types/MenuFilters";

const useDispensaryMenuFilterOptions = (
  filters: MenuFilterMap,
  filterSection: MenuItemFilterSection,
): MenuFilterOptions[] =>
  useMemo(
    () =>
      (filters &&
        (filters[filterSection] as MenuFiltersGroup)?.values.map((filter) => ({
          displayName: `${
            filterSection === "brand_name" ||
            filterSection === "strain_name" ||
            filterSection === "dispensary_name"
              ? filter.value
              : filter.label
          }`,
          docCount: filter.count,
          key: `${filters[filterSection].name}_${filter.value}`,
          showCount: filters[filterSection]?.showCount,
          ...filter,
        }))) ||
      [],
    [filters, filterSection],
  );

export default useDispensaryMenuFilterOptions;
