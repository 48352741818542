import { useCallback } from "react";

import { MenuItemFilterSection } from "../types/MenuFilters";

const useIcons = () => {
  const parseIconLabel = useCallback((label: string) => {
    const fileName = label.replace(/_/g, "-").toLowerCase();
    const tileLabel = label.replace(/_/g, " ").toLowerCase();
    return { fileName, tileLabel };
  }, []);

  const getIconPath = useCallback(
    (tileGroupName: MenuItemFilterSection, value: string) => {
      switch (tileGroupName) {
        case MenuItemFilterSection.StrainCategory:
          return `phenotypes/${parseIconLabel(value).fileName}.svg`;
        case MenuItemFilterSection.Flavor:
          return `flavors/${parseIconLabel(value).fileName}.svg`;
        case MenuItemFilterSection.EffectsExcluded:
          return `effects/${parseIconLabel(value).fileName}.svg`;
        case MenuItemFilterSection.EffectsIncluded:
          return `effects/${parseIconLabel(value).fileName}.svg`;
        default:
          return `${parseIconLabel(value).fileName}.svg`;
      }
    },
    [parseIconLabel],
  );

  return { getIconPath, parseIconLabel };
};

export default useIcons;
