import { useMemo } from "react";
import mergeWith from "lodash/mergeWith";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";
import { useRouter } from "next/router";

import parseNextRouterAsPath from "utils/parseNextRouterAsPath";

type Query = {
  [key: string]: string | string[] | undefined;
};

/**
 * These parameters are common keys that should not be considered
 * filters in this context.
 */
export const COMMON_SEARCH_PARAMS = ["q", "page", "sort"];

export const IGNORED_QUERIES = [
  // * Ignore other common search query params
  ...COMMON_SEARCH_PARAMS,
  // * Ignore Google recognized UTM parameters
  // * https://www.optimizesmart.com/custom-campaigns-google-analytics-complete-guide/
  "utm_source",
  "utm_medium",
  "utm_campaign",
  "utm_content",
  "utm_term",
  "itm_source",
  "itm_medium",
  "itm_campaign",
  "itm_content",
  "itm_term",
  "sign_out_redirect",
  "boost_strain",
  "disable_age_gate",
  "disable_cookie_popup",
  "boost",
  "boost_options",
  "mobile", // * Comes in from iOS when opening push links for some reason...
  "rdt_cid",
];

type HookOptions = {
  ignoredQueries?: string[];
  /**
   * shallowRouting: this util pushes shallow routes by default which are
   *  client rendered only. Setting this to false will enable full page
   *  transitions including calls to `getInitialProps` and `getServerSideProps`
   */
  shallowRouting?: boolean;
};

const DEFAULT_OPTIONS: HookOptions = { shallowRouting: true };

/**
 * useFilters
 * Provides non-ignored url query param values as well as utils
 * for interacting with them
 */
const useFilters = ({
  ignoredQueries,
  shallowRouting,
}: HookOptions = DEFAULT_OPTIONS) => {
  const { route, query, push, asPath } = useRouter();

  const { query: parsedQuery, path: pathString } =
    parseNextRouterAsPath(asPath);

  const ignoredQueryParams = useMemo(
    () =>
      ignoredQueries
        ? [...IGNORED_QUERIES, ...ignoredQueries]
        : IGNORED_QUERIES,
    [ignoredQueries],
  );

  // Memoizing because lodash tends to make new objects each run,
  // even if the underlying string is the same
  // https://lodash.com/docs/#omit
  const filterValues = useMemo(
    () => omit<Query>(parsedQuery, ignoredQueryParams),
    [JSON.stringify(parsedQuery), ignoredQueryParams],
  );

  /**
   * Whether or not any filters are currently applied
   */
  const filtersApplied = Object.keys(filterValues).length > 0;

  /**
   * Whether common search query params are applied (q, sort, page)
   */
  const commonSearchParamsApplied = COMMON_SEARCH_PARAMS.some(
    (key) => key in parsedQuery,
  );

  /**
   * Pushes query to existing route
   * @param {Query} newQuery - The query values to add (key/value)
   */
  const pushShallowRoute = (newQuery: Query) =>
    /**
     * Using push from next/router pushes a new url to the window and also adds the url to
     * the router history state, allowing for back button navigation to previous filter
     */
    push(
      {
        pathname: route,
        query: newQuery,
      },
      undefined,
      { shallow: shallowRouting },
    );

  /**
   * Adds new query values to the current query
   * @param {Query} newQuery - The query values to add (key/value)
   */
  const addFilter = (newQuery: Query) => {
    const nonNullQuery = pickBy({ ...query, ...newQuery });

    pushShallowRoute(nonNullQuery);
  };

  const addDispensaryFilter = (
    name: string,
    value: Query["key"],
    additionalQuery: Query = {},
  ) => {
    let newQuery: Query;
    if (name === "q") {
      newQuery = { q: value || undefined };
    } else {
      newQuery = { ...parsedQuery, ...additionalQuery, [name]: value };
    }

    // Clear page if a new selection is made
    if (name !== "page") {
      newQuery.page = undefined;
    }

    //clear out any undefined query params
    Object.keys(newQuery).forEach((key: string) => {
      if (!newQuery[key]) {
        delete newQuery[key];
      }
    });

    push(
      {
        pathname: route,
        query: newQuery,
      },
      {
        pathname: pathString,
        query: newQuery,
      },
      { shallow: shallowRouting },
    );
  };

  /**
   * Toggles query values based on the current query
   * @param {Query} newQuery - The query values to add (key/value)
   */
  const toggleFilter = (newQuery: Query) => {
    const mergedQuery = mergeWith(query, newQuery, filterToggler);
    const nonNullQuery = pickBy(mergedQuery);

    // reset page on filter/sort changes
    if (nonNullQuery?.page) {
      delete nonNullQuery.page;
    }

    pushShallowRoute(nonNullQuery);
  };

  /**
   * Exchanges first query value for the second query value
   * @param {Array} oldKeys - The keys to replace
   * @param {Query} newQuery - The query values to add (key/value)
   */
  const swapFilter = (oldKeys: string[], newQuery: Query) => {
    const mergedQuery = mergeWith(omit(filterValues, oldKeys), newQuery);
    const nonNullQuery = pickBy({ ...query, ...mergedQuery });

    // reset page on filter/sort changes
    if (nonNullQuery?.page) {
      delete nonNullQuery.page;
    }

    pushShallowRoute(nonNullQuery);
  };

  /**
   * Removes all query values.
   * NOTE: Since Next.js routing keeps dynamic route keys in the query object
   * we need to make sure we don't delete those by removing only the keys in the
   * parsedQuery (query params only) object from the query
   */
  const clearAllFilters = (keepKeys: string[] = []) => {
    let queryKeysToClear = Object.keys(parsedQuery).filter(
      (queryKey) => !keepKeys.includes(queryKey),
    );

    // * Append [] to filter keys to remove, since just about all filters
    // * can have multiple values at this time.
    queryKeysToClear = [
      ...queryKeysToClear,
      ...queryKeysToClear.map((key) => `${key}[]`),
    ];

    const originalQuery = omit(query, queryKeysToClear);

    push(
      {
        pathname: `${route}`,
        query: originalQuery,
      },
      undefined,
      { shallow: shallowRouting },
    );
  };

  /**
   * Selects a specific filter value
   * @param {String} name - The value to select
   */
  const selectFilterValue = (name: string) => parsedQuery[name];

  /**
   * Selects all filter values as an array
   * @param {String} name - The query string key for this filter
   */
  const selectFilterValues = (name: string): string[] => {
    const value = selectFilterValue(name);

    if (!value) {
      return [];
    } else if (Array.isArray(value)) {
      return value;
    } else {
      return [value];
    }
  };

  return {
    addDispensaryFilter,
    addFilter,
    clearAllFilters,
    commonSearchParamsApplied,
    filterValues,
    filtersApplied,
    selectFilterValue,
    selectFilterValues,
    swapFilter,
    toggleFilter,
  };
};

export function filterToggler(
  existingFilterValue: string | string[],
  newFilterValue: string,
) {
  // Check if a filter exists at all. If no filter, we'll just send
  // the new value as is.
  if (!existingFilterValue) {
    return newFilterValue;
  }

  // If we find a value and its a string, we'll check it exists already
  // If it doesnt, we'll combine the existing value with the new value.
  // If it doesn't, we'll return null
  if (typeof existingFilterValue === "string") {
    if (existingFilterValue !== newFilterValue) {
      return [existingFilterValue, newFilterValue];
    } else {
      return null;
    }
  }

  // If the filter we receive has multiple values contained in an array, we check
  // first to see if the value exists in the array. If it doesn't we add it to the
  // array and if does exist we remove it from the array.
  if (Array.isArray(existingFilterValue)) {
    if (
      existingFilterValue.findIndex((item) => item === newFilterValue) === -1
    ) {
      return [...existingFilterValue, newFilterValue];
    } else {
      const filtered = existingFilterValue.filter(
        (value) => value !== newFilterValue,
      );

      return filtered;
    }
  }
}

export default useFilters;
