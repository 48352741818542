import classNames from "classnames";

import useFilters from "hooks/useFilters";
import useIsStrainListPage from "hooks/useIsStrainListPage";

import { FilterChip } from "./FilterChip";
import { Facet } from "./FilteredGrid";

export const FilterChips = ({
  facets,
  onClick,
  className,
}: {
  facets: Facet[];
  // * NOTE: This is not an additive onClick.
  // * This rather overrides the baked in behavior of this component.
  // * See ProductFilterView for primary usage of this.
  onClick?: (facet: Facet) => void;
  className?: string;
}) => {
  const { toggleFilter } = useFilters();
  const { strainListPageName } = useIsStrainListPage();

  return (
    <div className={classNames("flex md:flex-wrap gap-1", className)}>
      {strainListPageName && (
        <FilterChip
          facet={{
            key: strainListPageName,
            label: strainListPageName,
            value: strainListPageName,
          }}
        />
      )}
      {facets.map(
        (facet) =>
          facet && (
            <FilterChip
              facet={facet}
              key={facet.value}
              onClick={() =>
                onClick
                  ? onClick(facet)
                  : toggleFilter({ [facet.key]: facet.value })
              }
            />
          ),
      )}
    </div>
  );
};
