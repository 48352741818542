import { useCallback, useMemo } from "react";
import intersectionWith from "lodash/intersectionWith";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";

import useFilters from "hooks/useFilters";

import { Facet } from "components/FilteredGrid";

import {
  MenuFilterGroupValues,
  MenuFilterMap,
  MenuFiltersGroup,
} from "../types/MenuFilters";

/**
 * This hook creates facets that are used by the FilterChip.
 * It ensures that facet data used by this component has both
 * the correct label and value.
 */

const useAPISupportedFilterFacets = (
  menuFilterMap?: MenuFilterMap,
  ignoredQueries: string[] = [],
) => {
  const { filterValues } = useFilters({
    ignoredQueries,
  });
  const createFacet = useCallback(
    (key: string, value: string, label?: string) => {
      return {
        key: `${key}[]`,
        label: label ? label : value,
        value,
      };
    },
    [],
  );

  const createToggleFacet = useCallback(
    (key: string, value: string) => {
      if (isEmpty(menuFilterMap) || menuFilterMap === undefined) {
        return [];
      }

      const filter = menuFilterMap[key];
      if (!filter) {
        return;
      }

      return {
        key,
        label: filter.label,
        value,
      };
    },
    [menuFilterMap],
  );

  const createAPISupportedFacetOptions = useCallback(
    (key: string, appliedFilterOptions: string[]) => {
      if (!menuFilterMap) {
        return [];
      }
      const apiSupportedFilterCategory = menuFilterMap[key] as MenuFiltersGroup;
      const apiSupportedFilterOptions: MenuFilterGroupValues[] =
        apiSupportedFilterCategory?.values || [];
      const apiSupportedValues = intersectionWith(
        apiSupportedFilterOptions,
        appliedFilterOptions,
        ({ value }, v) => isEqual(value, v),
      );
      return apiSupportedValues.map(({ value, chip }) =>
        createFacet(key, value, chip),
      );
    },
    [menuFilterMap, createFacet],
  );

  const apiSupportedFacets = useMemo(
    (): Facet[] =>
      Object.keys(filterValues || []).reduce(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
        (prevFacets: any[], key: string) => {
          const value = filterValues[key];
          const valueIsSearchQuery = key === "q";
          const facetIsToggle = value === "true";
          if (!value) {
            return [];
          }
          if (facetIsToggle) {
            const facet = [createToggleFacet(key, value)];
            return prevFacets.concat(facet);
          }
          // * NOTE: When value is a search query, filterValues returns a string
          // * which is why the falsy statement calls createFacet directly.
          const facets = valueIsSearchQuery
            ? [createFacet(key, value as string)]
            : createAPISupportedFacetOptions(key, value as string[]);

          return prevFacets.concat(facets);
        },
        [],
      ),
    [
      createAPISupportedFacetOptions,
      createFacet,
      createToggleFacet,
      filterValues,
    ],
  );

  return apiSupportedFacets;
};

export default useAPISupportedFilterFacets;
