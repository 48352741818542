import { useCallback, useMemo } from "react";
import { useRouter } from "next/router";
import { parse, stringify } from "qs";

const reconcileExistingValuesAndNew = (
  appliedFilterValue: string | string[],
  newFilterValue: string,
): string | string[] | undefined => {
  if (Array.isArray(appliedFilterValue)) {
    // * We've already got a collection of values.
    // * We'll add or remove the requested value, depending on whether its already present.
    return appliedFilterValue.includes(newFilterValue)
      ? appliedFilterValue.filter((v) => v !== newFilterValue)
      : [...appliedFilterValue, newFilterValue];
  } else if (appliedFilterValue === newFilterValue) {
    // * This filter exists, and is a single value equal to the requested value.
    // * This likely means the user has clicked an applied filter, and thus wishes to remove it.
    return undefined;
  } else {
    // * A filter value was found, but was different than the requested value.
    // * User is likely expanding their selection for a particular filter section.
    return [appliedFilterValue, newFilterValue];
  }
};

const getUpdatedFilterValue = (
  appliedFilterValue: string | string[] | undefined,
  newFilterValue: string,
): string | string[] | undefined => {
  if (newFilterValue === "true") {
    // * Support for toggle filters.
    return appliedFilterValue === "true" ? undefined : "true";
  } else if (appliedFilterValue) {
    return reconcileExistingValuesAndNew(appliedFilterValue, newFilterValue);
  } else {
    // * No existing filters applied, adding the requested value.
    return newFilterValue;
  }
};

export const useFilterQueryParams = () => {
  const { asPath, push } = useRouter();

  const [url, _hash] = asPath.split("#");
  const [path, query] = url.split("?");

  const { appliedFilters, nonFilterQueryParams } = useMemo(() => {
    const parsedQs = parse(query);
    // * Use the "filter" object in the query string by default, splitting it from other root level query string params.
    const { filter: _filter, ...nonFilterQueryParams } = parsedQs;
    // * This const should always be an record of key/values as long as the filter[] querystring params are solely modified by this hook.
    const appliedFilters =
      (parsedQs.filter as { [key: string]: string | string[] | undefined }) ||
      {};

    return {
      appliedFilters,
      nonFilterQueryParams,
    };
  }, [query]);

  const toggleFilter = useCallback(
    (name: string, newFilterValue: string) => {
      const { [name]: appliedFilterValue, ...otherFilters } = appliedFilters;

      const filterValue = getUpdatedFilterValue(
        appliedFilterValue,
        newFilterValue,
      );

      push(
        {
          pathname: path,
          query: stringify(
            {
              ...nonFilterQueryParams,
              filter: {
                ...otherFilters,
                [name]: filterValue,
              },
            },
            // * Needed to more closely match now Next formats query string arrays / objects
            { indices: false },
          ),
        },
        undefined,
        { shallow: true },
      );
    },
    [appliedFilters, nonFilterQueryParams, path],
  );

  const clearAllFilters = useCallback(() => {
    const includesStrainBoost = nonFilterQueryParams.boost === "strain_name";
    let paramsToKeep = nonFilterQueryParams;
    if (includesStrainBoost) {
      // eslint-disable-next-line unused-imports/no-unused-vars -- exemption removing object keys
      const { boost, ...sansBoost } = nonFilterQueryParams;
      // eslint-disable-next-line unused-imports/no-unused-vars -- exemption removing object keys
      const { boost_options, ...sansBoostOptions } = sansBoost;
      paramsToKeep = sansBoostOptions;
    }
    push(
      {
        pathname: path,
        query: stringify(paramsToKeep),
      },
      undefined,
      { shallow: true },
    );
  }, [path, nonFilterQueryParams]);

  return {
    actions: {
      clearAllFilters,
      toggleFilter,
    },
    appliedFilters,
  };
};

export default useFilterQueryParams;
