import ExpandableFilterOptions from "components/ExpandableFilterOptions";
import FilterCheckbox from "components/Filters/FilterCheckbox/FilterCheckbox";

export const DEFAULT_VISIBLE_TAKE = 7;

export type FilterCheckboxOption = {
  key: string;
  label: string;
  showCount?: boolean;
  value: string;
  loading?: boolean;
  docCount: number;
  displayName: string;
};

export const FilterCheckboxList: React.FC<{
  onChange?: (changed: FilterCheckboxOption) => void;
  selected?: (option: FilterCheckboxOption) => boolean;
  disabled?: (option: FilterCheckboxOption) => boolean;
  options: FilterCheckboxOption[];
  visibleTake?: number;
  loading?: boolean;
  name: string;
  renderCustomLabel?: (filter: FilterCheckboxOption) => React.ReactNode;
}> = ({
  name,
  onChange,
  selected,
  disabled,
  options,
  loading,
  visibleTake = DEFAULT_VISIBLE_TAKE,
  renderCustomLabel,
}) => {
  const visibleOptions = options.slice(0, visibleTake);
  const hiddenOptions = options.slice(visibleTake);

  return (
    <>
      <div
        className="overflow-auto max-h-80"
        data-testid="filter-checkbox-list"
      >
        <Checkboxes
          options={visibleOptions}
          onChange={onChange}
          name={name}
          selected={selected}
          disabled={disabled}
          loading={loading}
          renderCustomLabel={renderCustomLabel}
        />

        {Boolean(hiddenOptions.length) && (
          <ExpandableFilterOptions
            expandButtonText={`Show all ${options.length.toLocaleString(
              "en-us",
            )}`}
          >
            <Checkboxes
              name={name}
              options={hiddenOptions}
              onChange={onChange}
              loading={loading}
              disabled={disabled}
              selected={selected}
              renderCustomLabel={renderCustomLabel}
            />
          </ExpandableFilterOptions>
        )}
      </div>
    </>
  );
};

const Checkboxes: React.FC<{
  onChange?: (changed: FilterCheckboxOption) => void;
  selected?: (option: FilterCheckboxOption) => boolean;
  disabled?: (option: FilterCheckboxOption) => boolean;
  options: FilterCheckboxOption[];
  name: string;
  renderCustomLabel?: (filter: FilterCheckboxOption) => React.ReactNode;
  loading?: boolean;
}> = ({
  onChange,
  options,
  name,
  disabled,
  selected,
  renderCustomLabel,
  loading,
}) => (
  <>
    {options.map((filter) => (
      <FilterCheckbox
        count={filter.docCount}
        key={filter.key}
        name={name}
        loading={loading}
        value={filter.value}
        showCount={filter?.showCount}
        onChange={() => {
          onChange?.(filter);
        }}
        selected={selected?.(filter)}
        disabled={disabled?.(filter)}
      >
        {renderCustomLabel ? renderCustomLabel(filter) : filter.label}
      </FilterCheckbox>
    ))}
  </>
);

export default FilterCheckboxList;
